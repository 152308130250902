import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { SimpleContentPage } from '@mangoart/gatsby-ui/components/ezagrar/SimpleContentPage';
import DefaultLayout from '../layout/DefaultLayout';

const UeberUnsPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <div className={'defaultWidth'}>
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
        <SimpleContentPage {...content} />
      </div>
    </DefaultLayout>
  );
};

export default UeberUnsPage;

export const UeberUnsPageQuery = graphql`
  query UeberUnsPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageUnternehmen {
      content {
        title
        pageID
        teaser
        text
        image {
          publicURL
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
